export const SET_USER = "[MAIN] __SET_USER__ "
export const SET_EXP_REMIND = "[MAIN] __SET_EXP_REMIND "
export const SET_USER_PROFILE = "[MAIN] SET_USER_PROFILE"
export const OPEN_AUTH_DIALOG = "[MAIN] _OPEN_AUTH_DIALOG "
export const SET_CONFIG= "[MAIN] SET_CONFIG"
export const ADD_PARTICIPANT= "[MAIN] ADD_PARTICIPANT"
export const REMOVE_PARTICIPANT= "[MAIN] REMOVE_PARTICIPANT"
export const FLUSH_PARTICIPANT= "[MAIN] FLUSH_PARTICIPANT"
export const PROCESS_PENDING_PARTICIPANTS = "[MAIN] PROCESS_PENDING_PARTICIPANTS"
export const AVATAR_DIALOG_OPEN = "[MAIN] AVATAR_DIALOG_OPEN"
export const ADD_ACTIVE= "[MAIN] ADD_ACTIVE"
export const DELETE_ACTIVE = "[MAIN] DELETE_ACTIVE"
export const SET_NOUSER = "[MAIN] SET_NO_USER"
export const SET_GVPAGE = "[MAIN] SET_GVPAGE"
export const SET_SESSION_DIALOG = "[MAIN] SET_SESSION_DIALOG"
export const SET_BOARD_TYPE = "[MAIN] SET_BOARD_TYPE"
export const SET_TAB = "[MAIN] SET_TAB"
export const SET_TEACHER = "[MAIN] SET_TEACHER"
export const SET_COUNTRY= "[MAIN] SET_COUNTRY"
export const SET_CLIENT_LOC= "[MAIN] SET_CLIENT_LOC"
export const RAISE_HAND= "[MAIN] RAISE_HAND"
export const ASSISTANCE_REQUEST= "[MAIN] ASSISTANCE_REQUEST"
export const CHAT_MSG= "[MAIN] CHAT_MSG"
export const TIMER_EVENT= "[MAIN] TIMER_EVENT"
export const BOARD_LOCK= "[MAIN] BOARD_LOCK"
export const PAGE_LOCK= "[MAIN] PAGE_LOCK"
export const SET_PALETTE= "[MAIN] SET_PALETTE"
export const SHOWCASE= "[MAIN] SHOWCASE"
export const SET_RICH_TEXT= "[MAIN] SET_RICH_TEXT"
export const SET_DICE= "[MAIN] SET_DICE"
export const SET_FOLLOW= "[MAIN] SET_FOLLOW"
export const SET_CLOCK_DRIFT= "[MAIN] SET_CLOCK_DRIFT"
export const SET_NOTIFICATION= "[MAIN] SET_NOTIFICATION"
export const SET_SYNC_DISABLED= "[MAIN] SET_SYNC_DISABLED"
export const SET_DRAWER_WALKER= "[MAIN] SET_DRAWER_WALKER"
export const SET_GRADING_WALKER= "[MAIN] SET_GRADING_WALKER"
export const SET_CLICK_MODE= "[MAIN] SET_CLICK_MODE"
export const SET_COMMUNITYUSER = "[MAIN] SET_COMMUNITYUSER"
export const SET_SPINNER_EVENT = "[MAIN] SET_SPINNER_EVENT"
export const SET_PERSONAL_CONFIG = "[MAIN] SET_PERSONAL_CONFIG"
export const SET_GRID_BROWSER = "[MAIN] SET_GRID_BROWSER"
export const SET_MATRIX_GRID = "[MAIN] SET_MATRIX_GRID"
export const SET_LETITSNOW_EVENT = "[MAIN] SET_LETITSNOW_EVENT"
export const SET_TIME_MACHINE = "[MAIN] SET_TIME_MACHINE"
export const SET_BACK_GROUND = "[MAIN] SET_BACK_GROUND"
export const SET_BOARD_TOOLS = "[MAIN] SET_BOARD_TOOLS"
export const SET_FDICE_EVENT = "[MAIN] SET_FDICE_EVENT"
export const ALL_EVENTS = "[MAIN] ALL_EVENTS"
export const FORM_SUBMIT = "[MAIN] FORM_SUBMIT"
export const UPDATE_ENGAGEMENT_SCORE = "[MAIN] UPDATE_ENGAGEMENT_SCORE"
export const MEETING_TO_JOIN = "[MAIN] MEETING_TO_JOIN"
export const SET_API_MODE = "[MAIN] SET_API_MODE"
export const SET_ENGAGEMENT_STICKER = "[MAIN] SET_ENGAGEMENT_STICKER"
export const SET_KEYB_MODE = "[MAIN] SET_KEYB_MODE"
export const SET_VIDEO_STATE = "[MAIN] SET_VIDEO_STATE"
export const SET_MODE = "[MAIN] SET_MODE"
export const SET_MOBILE_MODE = "[MAIN] SET_MOBILE_MODE"
export const SET_ALERT_MESSAGES = "[MAIN] SET_ALERT_MESSAGES"
export const POP_ALERT_MESSAGES = "[MAIN] POP_ALERT_MESSAGES"
export const SET_VIDEO_CONFIG = "[MAIN] SET_VIDEO_CONFIG"
export const PENDING_SUBSCRIPTIONS = "[MAIN] PENDING_SUBSCRIPTIONS"
export const SET_SHARED_SCREEN = "[MAIN] SET_SHARED_SCREEN"
export const SET_BOARD_RECORDING = "[MAIN] SET_BOARD_RECORDING"
export const SET_PAGE_ADDING = "[MAIN] SET_PAGE_ADDING"

export function setPageAdding(data) {
    return {
        type: SET_PAGE_ADDING,
        data
    }
}
export function setBoardRecording(data) {
    return {
        type: SET_BOARD_RECORDING,
        data
    }
}
export function setSharedScreen(data) {
    return {
        type: SET_SHARED_SCREEN,
        data
    }
}
export function setPendingSubsciptions(data) {
    return {
        type: PENDING_SUBSCRIPTIONS,
        data
    }
}
export function setVideoConfig(data) {
    return {
        type: SET_VIDEO_CONFIG,
        data
    }
}
export function setAlertMessages(data) {
    return {
        type: SET_ALERT_MESSAGES,
        data
    }
}
export function popAlertMessage() {
    let data = null
    return {
        type: POP_ALERT_MESSAGES,
        data
    }
}
export function setMobileMode(data) {
    return {
        type: SET_MOBILE_MODE,
        data
    }
}
export function setMode(data) {
    return {
        type: SET_MODE,
        data
    }
}
export function setVideoState(data) {
    return {
        type: SET_VIDEO_STATE,
        data
    }
}
export function setKeyBMode(data) {
    return {
        type: SET_KEYB_MODE,
        data
    }
}

export function disableKeyBMode() {
    let data = {enable: false, lastKey: ""}
    return {
        type: SET_KEYB_MODE,
        data
    }
}

export function setEngagementSticker(data) {
    return {
        type: SET_ENGAGEMENT_STICKER,
        data
    }
}
export function setApiMode(data) {
    return {
        type: SET_API_MODE,
        data
    }
}
export function meetingToJoin(data) {
    return {
        type: MEETING_TO_JOIN,
        data
    }
}
export function updateEngagementScore(data) {
    return {
        type: UPDATE_ENGAGEMENT_SCORE,
        data
    }
}
export function addFormSubmit(data) {
    return {
        type: FORM_SUBMIT,
        data
    }
}
export function addAllEvents(data) {
    return {
        type: ALL_EVENTS,
        data
    }
}
export function setBoardTools(data) {
    return {
        type: SET_BOARD_TOOLS,
        data
    }
}

export function setBackGround(data) {
    return {
        type: SET_BACK_GROUND,
        data
    }
}

export function setTimeMachine(data) {
    return {
        type: SET_TIME_MACHINE,
        data
    }
}
export function setMatrixGrid(data) {
    return {
        type: SET_MATRIX_GRID,
        data
    }
}
export function setGridBrowser(data) {
    return {
        type: SET_GRID_BROWSER,
        data
    }
}
export function setClickMode(data) {
    return {
        type: SET_CLICK_MODE,
        data
    }
}

export function setPersonalConfig(data) {
    return {
        type: SET_PERSONAL_CONFIG,
        data
    }
}

export function setDrawerWalker(data) {
    return {
        type: SET_DRAWER_WALKER,
        data
    }
}

export function setGradingWalker(data) {
    return {
        type: SET_GRADING_WALKER,
        data
    }
}

export function setSyncDisabled(data) {
    return {
        type: SET_SYNC_DISABLED,
        data
    }
}
export function setNotification(data) {
    return {
        type: SET_NOTIFICATION,
        data
    }
}
export function setDrift(data) {
    return {
        type: SET_CLOCK_DRIFT,
        data
    }
}
export function setFollow(data) {
    return {
        type: SET_FOLLOW,
        data
    }
}
export function showDice(data) {
    return {
        type: SET_DICE,
        data
    }
}
export function setRichText(data) {
    return {
        type: SET_RICH_TEXT,
        data
    }
}
export function setShowcase(data) {
    return {
        type: SHOWCASE, 
        data
    }  
}
export function setPalette(data) {
    return {
        type: SET_PALETTE, 
        data
    }  
}
export function setBoardLock(data) {
    return {
        type: BOARD_LOCK, 
        data
    }  
}
export function setPageLock(data) {
    return {
        type: PAGE_LOCK, 
        data
    }  
}
export function setTimerEvent(data) {
    return {
        type: TIMER_EVENT, 
        data
    }  
}
export function setRaiseHand(data) {
    return {
        type: RAISE_HAND, 
        data
    }  
}
export function setChatMsg(data) {
    return {
        type: CHAT_MSG, 
        data
    }  

}
export function assistanceRequest(data) {
    return {
        type: ASSISTANCE_REQUEST, 
        data
    }  
}

export function setTeacher(data) {
    return {
        type: SET_TEACHER, 
        data
    }  
}

export function setCountry(data) {
    return {
        type: SET_COUNTRY, 
        data
    }  
}

export function setClientLoc(data) {
    return {
        type: SET_CLIENT_LOC,
        data
    }
}

export function setBoardType(data) {
    return {
        type: SET_BOARD_TYPE, 
        data
    }  
}

export function setTab(data) {
    return {
        type: SET_TAB, 
        data
    }  
}

export function setSessionDialog(data) {
    return {
        type: SET_SESSION_DIALOG, 
        data
    }
}

export function setGVPage(data) {
    return {
        type: SET_GVPAGE,
        data
    }
}

export function setNoUser() {
    return {
        type: SET_NOUSER,
    }
}

export function setUser(data) {
    return {
        type: SET_USER,
        data
    }
}

export function setNotificationRemind(data) {
    return {
        type: SET_EXP_REMIND,
        data
    }
}

export function setUserProfile(data) {
    return {
        type: SET_USER_PROFILE,
        data
    }
}

export function openAuthDialog(data) {
    return {
        type: OPEN_AUTH_DIALOG,
        data
    }
}

export function setBoardConfig(data) {
    if (data.starterMode) {
        data.fourToolsStudent = true
    }
    return {
        type: SET_CONFIG,
        data
    }
}
export function addParticipant(data) {
    return {
        type: ADD_PARTICIPANT,
        data
    }
}
export function removeParticipants(data) {
    return {
        type: REMOVE_PARTICIPANT,
        data
    }
}
export function flushParticipants() {
    return {
        type: FLUSH_PARTICIPANT
    }
}
export function processPendingParticipants() {
    return {
        type: PROCESS_PENDING_PARTICIPANTS
    }
}

export function setAvatarDialogOpen(data) {
    return {
        type: AVATAR_DIALOG_OPEN,
        data
    }
}

export function addActive(data) {
    return {
        type: ADD_ACTIVE,
        data
    }
}
export function deleteActive(data) {
    return {
        type: DELETE_ACTIVE,
        data
    }
}

export function setCommunityUser(data) {
    return {
        type: SET_COMMUNITYUSER,
        data
    }
}

export function spinnerEvent(data) {
    return {
        type: SET_SPINNER_EVENT,
        data
    }
}

export function fDiceEvent(data) {
    return {
        type: SET_FDICE_EVENT,
        data
    }
}

export function getPageLock(pg) {
    const JOINURL = window.location.href.split("/board/")
    var sessID = JOINURL[1].split("-pgNum-")
    var match = false 
    var ev = null 
    for (let i=0; i< pg.events.length; i++) {
        const p = pg.events[i]
        var ff = p.id.split("-LockPage-")
        if (ff[1] === sessID[1]) {
            match = true
            ev = p 
            break 
        }
    }
    return ({pgNum: sessID[1], locked: match, ev: ev})
}
export function letItSnowEvent(data) {
    return {
        type: SET_LETITSNOW_EVENT,
        data
    }
}